import clsx from 'clsx';
import {
	ChangeEventHandler,
	FocusEventHandler,
	FormEventHandler,
	ForwardedRef,
	forwardRef,
	HTMLProps,
	useCallback,
	useEffect,
	useState,
} from 'react';
import * as yup from 'yup';
import { yupErrorToErrorObject } from '../../../../util/validation';
import { ValidationIcon } from '../validation-icon';
import { InputIcon } from '../input-icon';
import { InputError } from './input-error';

type Props = {
	name: string;
	icon?: JSX.Element;
	error?: string;
	validationSchema?: yup.AnySchema;
	validIcon?: any;
	invalidIcon?: any;
} & HTMLProps<HTMLInputElement>;

export const Input = forwardRef((props: Props, ref: ForwardedRef<HTMLInputElement>) => {
	const { type, validationSchema, icon, validIcon, invalidIcon, ...inputProps } = props;

	//#region validation
	const [error, setError] = useState<string | undefined>(undefined);
	const [touched, setTouched] = useState<boolean>(false);
	const [valid, setValid] = useState<boolean>(false);

	const validate = useCallback(
		(value: string) => {
			if (!validationSchema) return; // no schema -> no validation

			// create yup schema
			const schema = yup.object().shape({
				[props.name]: validationSchema,
			});

			// validate
			schema
				.validate({ [props.name]: value }, { abortEarly: false })
				.then(() => {
					setError(undefined);
					setValid(true);
				})
				.catch((err: yup.ValidationError) => {
					const errorObject = yupErrorToErrorObject(err);
					setError(errorObject[props.name].join('; ') || '');
					setValid(false);
				});
		},
		[props.name, validationSchema]
	);

	// validate on value change
	useEffect(() => {
		if (typeof props.value === 'string' && props.value !== '') {
			setTouched(true);
			validate(props.value);
		}
	}, [props.value, validate]);
	//#endregion
	//#region input event handler
	const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
		// call default on change handler
		if (props.onChange) {
			props.onChange(e);
		}

		if (touched) {
			validate(e.currentTarget.value);
		}
	};

	const handleFocusLeave: FocusEventHandler<HTMLInputElement> = (e) => {
		setTouched(true);
		validate(e.currentTarget.value);
	};

	const handleReset: FormEventHandler<HTMLInputElement> = () => {
		setError(undefined);
		setTouched(false);
		setValid(false);
	};
	//#endregion

	const isError = props.error !== undefined || error !== undefined;
	const isValid = validationSchema && touched && valid && !isError;

	return (
		<div className='relative flex flex-col items-start'>
			<div className='relative w-full'>
				<input
					ref={ref}
					type='text'
					className={clsx(
						'select-text',
						'relative',
						'w-full px-6 py-2 rounded-full',
						'border-2 border-transparent font-bold',
						'bg-form-bg dark:bg-form-bg-dark',
						'text-typography dark:text-typography-dark',
						'disabled:text-typography-secondary dark:disabled:text-typography-secondary-dark',
						'ring-0 outline-none',
						'transition-all duration-200',
						'focus:shadow-lg focus:ring-0 focus:outline-none', // focus
						'disabled:text-typography-secondary dark:disabled:text-typography-secondary-dark', // disabled
						'focus:border-middle dark:focus:border-middle-dark',
						{
							'pl-9': icon !== undefined,
							'pr-9': isValid || isError,
							'focus:border-2 focus:border-middle dark:focus:border-middle-dark':
								!isError && !isValid,
							'border-1 border-red-500': isError,
							'border-1 border-green-600': isValid,
						}
					)}
					{...inputProps}
					id={props.name}
					aria-invalid={isError ? 'true' : 'false'}
					aria-describedby={`${props.name}-error`}
					onChange={handleChange}
					onBlur={handleFocusLeave}
					onReset={handleReset}
				/>
				{icon && <InputIcon icon={icon} />}
				<ValidationIcon
					isValid={isValid}
					isError={isError}
					validIcon={validIcon}
					invalidIcon={invalidIcon}
				/>
			</div>
			<InputError name={props.name} isError={isError} error={error || props.error} />
		</div>
	);
});
Input.displayName = 'Input';
