export default (
	<svg viewBox='0 0 140 140' xmlns='http://www.w3.org/2000/svg'>
		<g transform='matrix(5.833333333333333,0,0,5.833333333333333,0,0)'>
			<path
				d='M1.5,16.608l3.093-3.789a1.639,1.639,0,0,1,1.676-.566h0a1.638,1.638,0,0,1,1.068,2.321l-.587,1.174H13.5a1.5,1.5,0,0,1,1.5,1.5h0a1.5,1.5,0,0,1-1.5,1.5H9l-.332,1.994a3,3,0,0,1-2.96,2.506H1.5'
				fill='none'
				stroke='currentColor'
				strokeLinecap='round'
				strokeLinejoin='round'
				strokeWidth='1.5'
			></path>
			<path
				d='M16.498 0.748L16.498 8.248'
				fill='none'
				stroke='currentColor'
				strokeLinecap='round'
				strokeLinejoin='round'
				strokeWidth='1.5'
			></path>
			<path
				d='M20.248 4.498L16.498 0.748 12.748 4.498'
				fill='none'
				stroke='currentColor'
				strokeLinecap='round'
				strokeLinejoin='round'
				strokeWidth='1.5'
			></path>
			<path
				d='M12.255,13.006a6,6,0,1,1,0,8.485'
				fill='none'
				stroke='currentColor'
				strokeLinecap='round'
				strokeLinejoin='round'
				strokeWidth='1.5'
			></path>
		</g>
	</svg>
);
