import Link from 'next/link';
import { FC } from 'react';
import { AnchorButtonProps } from './types';
import { getButtonClassName, GetButtonClassNameProps } from './util';
import { ButtonBefore } from './_button-before';
import { TextButtonContent } from './_text-button-content';

export const AnchorButton: FC<AnchorButtonProps> = (props) => {
	//#region props
	const {
		// button base props
		size,
		variant,
		noShadow,
		noHover,
		noFocus,
		noActive,
		rounded,
		contentClassName,
		textGradient,
		ringClassName,
		backgroundClassName,
		label,
		// button props
		fullWidth,
		icon,
		iconPosition,
		iconOutside,
		iconClassName,
		iconProps,
		// link props
		href,
		as,
		passHref,
		prefetch,
		replace,
		scroll,
		shallow,
		locale,
		legacyBehavior,
		onMouseEnter,
		onTouchStart,
		onClick,
		// a props
		className,
		...buttonProps
	} = props;
	const linkProps = {
		href,
		as,
		passHref,
		prefetch,
		replace,
		scroll,
		shallow,
		locale,
		legacyBehavior,
		onMouseEnter,
		onTouchStart,
		onClick,
	};
	const getButtonClassNameProps: GetButtonClassNameProps = {
		variant,
		noShadow,
		noHover,
		noFocus,
		noActive,
		rounded,
		fullWidth,
		className,
	};
	//#endregion

	return (
		<Link
			{...linkProps}
			className={getButtonClassName(getButtonClassNameProps)}
			aria-label={label}
			{...buttonProps}
		>
			<ButtonBefore
				variant={variant}
				ringClassName={ringClassName}
				backgroundClassName={backgroundClassName}
			/>
			<TextButtonContent
				size={size}
				text={props.children}
				fullWidth={fullWidth}
				iconOutside={iconOutside}
				contentClassName={contentClassName}
				textGradient={textGradient}
				icon={icon}
				iconPosition={iconPosition}
				iconClassName={iconClassName}
			/>
		</Link>
	);
};

AnchorButton.displayName = 'Button';
AnchorButton.defaultProps = {
	variant: 'default',
	size: 'default',
	noShadow: false,
	noHover: false,
	noFocus: true,
	noActive: false,
	rounded: true,
	ringClassName: 'ring-background dark:ring-background-dark',
	backgroundClassName: 'bg-background dark:bg-background-dark',
	textGradient: true,
	fullWidth: false,
	iconPosition: 'start',
	iconOutside: false,
	href: '/',
};
