import clsx from 'clsx';
import { FC, ReactNode } from 'react';
import { ButtonSize, IconPosition } from './types';

type Props = {
	size?: ButtonSize;
	text: ReactNode;
	fullWidth?: boolean;
	iconOutside?: boolean;
	contentClassName?: string;
	textGradient?: boolean;
	icon?: JSX.Element;
	iconPosition?: IconPosition;
	iconClassName?: string;
};
export const TextButtonContent: FC<Props> = (props) => {
	const { size, text, fullWidth, iconOutside, icon, iconPosition } = props;

	const Icon = icon && (
		<div
			className={clsx(
				'icon',
				{ 'text-gradient': props.textGradient },
				iconPosition,
				props.iconClassName
			)}
		>
			{icon}
		</div>
	);

	return (
		<div
			className={clsx(
				'content',
				'w-full flex justify-center',
				{
					'px-4 py-2': size === 'sm',
					'px-6 py-2.5': size === 'default',
				},
				props.contentClassName
			)}
		>
			{iconPosition === 'start' && Icon}
			<span
				className={clsx('text', {
					'flex-1': fullWidth && iconOutside,
					'text-gradient': props.textGradient,
				})}
			>
				{text}
			</span>
			{iconPosition === 'end' && Icon}
		</div>
	);
};
