import { ForwardedRef, forwardRef } from 'react';
import { ButtonProps } from './types';
import { getButtonClassName, GetButtonClassNameProps } from './util';
import { ButtonBefore } from './_button-before';
import { TextButtonContent } from './_text-button-content';

export const Button = forwardRef((props: ButtonProps, ref: ForwardedRef<HTMLButtonElement>) => {
	//#region props
	const {
		// button base props
		variant,
		size,
		noShadow,
		noHover,
		noFocus,
		noActive,
		rounded,
		contentClassName,
		textGradient,
		ringClassName,
		backgroundClassName,
		label,
		// button props
		fullWidth,
		icon,
		iconPosition,
		iconOutside,
		iconClassName,
		iconProps,
		// rest props
		className,
		...buttonProps
	} = props;
	const getButtonClassNameProps: GetButtonClassNameProps = {
		variant,
		noShadow,
		noHover,
		noFocus,
		noActive,
		rounded,
		fullWidth,
		className,
	};
	//#endregion

	return (
		<button
			ref={ref}
			className={getButtonClassName(getButtonClassNameProps)}
			aria-label={label}
			{...buttonProps}
		>
			<ButtonBefore
				variant={variant}
				ringClassName={ringClassName}
				backgroundClassName={backgroundClassName}
			/>
			<TextButtonContent
				size={size}
				text={props.children}
				fullWidth={fullWidth}
				iconOutside={iconOutside}
				contentClassName={contentClassName}
				textGradient={textGradient}
				icon={icon}
				iconPosition={iconPosition}
				iconClassName={iconClassName}
			/>
		</button>
	);
});

Button.displayName = 'Button';
Button.defaultProps = {
	variant: 'default',
	size: 'default',
	noShadow: false,
	noHover: false,
	noFocus: true,
	noActive: false,
	rounded: true,
	ringClassName: 'ring-background dark:ring-background-dark',
	backgroundClassName: 'bg-background dark:bg-background-dark',
	fullWidth: false,
	textGradient: true,
	iconPosition: 'start',
	iconOutside: false,
};
