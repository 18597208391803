import clsx from 'clsx';
import { FC } from 'react';

type Props = {
	text: string;
};
export const ButtonTooltip: FC<Props> = ({ text }) => (
	<div
		className={clsx(
			'tooltip',
			'invisible absolute opacity-0 bottom-16 left-1/2',
			'px-4 py-1 bg-primary text-white rounded-full',
			'transition-opacity duration-200 delay-1000'
		)}
	>
		<span>{text}</span>
	</div>
);
