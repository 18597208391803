import { FC, PropsWithChildren } from 'react';

interface Props {
	icon: JSX.Element;
}
export const ContactDetailsItem: FC<PropsWithChildren<Props>> = (props) => {
	return (
		<div className='flex flex-row items-center'>
			<div className='rounded-full w-10 h-10 p-2 mr-4 bg-gradient-to-r from-primary dark:from-primary-dark to-secondary dark:to-secondary-dark'>
				<div className='w-full h-full text-main-bg dark:text-main-bg-dark'>{props.icon}</div>
			</div>
			<div className='flex flex-1 items-center '>
				<span className='hover:text-middle dark:hover:text-middle-dark focus:text-middle dark:focus:text-middle-dark'>
					{props.children}
				</span>
			</div>
		</div>
	);
};
