import { AnimatePresence, motion } from 'framer-motion';
import { FC } from 'react';

type Props = {
	name: string;
	isError?: boolean;
	error?: string;
};
export const InputError: FC<Props> = (props) => {
	return (
		<AnimatePresence>
			{props.isError && (
				<motion.p
					className='w-full px-3 text-sm text-red-500 dark:text-red-500'
					id={`${props.name}-error`}
					initial={{ opacity: 0, height: 0, marginTop: 0 }}
					animate={{ opacity: 1, height: 'auto', marginTop: '.25rem' }}
					exit={{ opacity: 0, height: 0, marginTop: 0 }}
				>
					{props.error}
				</motion.p>
			)}
		</AnimatePresence>
	);
};
