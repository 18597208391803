import { FC } from 'react';
import { motion } from 'framer-motion';
import clsx from 'clsx';
import { icons } from '../../../assets/icons';

type IconAlignment = 'top' | 'center' | 'bottom';

type Props = {
	isValid?: boolean;
	isError?: boolean;
	validIcon?: JSX.Element;
	invalidIcon?: JSX.Element;
	align?: IconAlignment;
};
export const ValidationIcon: FC<Props> = (props) => {
	const { isValid, isError, validIcon, invalidIcon } = props;

	const errorIcon = isError ? invalidIcon : undefined;

	const icon = isValid ? validIcon : errorIcon;
	if (!icon) return null;

	const align = props.align || 'center';

	return (
		<motion.div
			className={clsx('absolute inset-y-0 right-0 pr-3 flex pointer-events-none', {
				'pt-3 items-start': align === 'top',
				'items-center': align === 'center',
				'pb-3 items-end': align === 'bottom',
			})}
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
		>
			<div
				className={clsx('w-5 h-5', 'transition-colors duration-200', {
					'text-green-600': isValid,
					'text-red-500': isError,
				})}
			>
				{icon}
			</div>
		</motion.div>
	);
};
ValidationIcon.defaultProps = {
	validIcon: icons.valid,
	invalidIcon: icons.invalid,
};
