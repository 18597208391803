export default (
	<svg viewBox='0 0 140 140' xmlns='http://www.w3.org/2000/svg'>
		<g transform='matrix(5.833333333333333,0,0,5.833333333333333,0,0)'>
			<path
				d='M15.8,24H4.8a3,3,0,0,1-3-3v-6.5a1,1,0,0,1,2,0V21a1,1,0,0,0,1,1h11a1,1,0,0,0,1-1v-6.5a1,1,0,0,1,2,0V21A3,3,0,0,1,15.8,24Z'
				fill='currentColor'
				stroke='none'
				strokeLinecap='round'
				strokeLinejoin='round'
				strokeWidth='0'
			></path>
			<path
				d='M14.3,20h-8a1,1,0,0,1,0-2h8a1,1,0,1,1,0,2Z'
				fill='currentColor'
				stroke='none'
				strokeLinecap='round'
				strokeLinejoin='round'
				strokeWidth='0'
			></path>
			<path
				d='M14.68,17.27l-.21,0L6.65,15.59a1,1,0,0,1,.42-2l7.83,1.66a1,1,0,0,1-.21,2Z'
				fill='currentColor'
				stroke='none'
				strokeLinecap='round'
				strokeLinejoin='round'
				strokeWidth='0'
			></path>
			<path
				d='M15.62,14.72a1,1,0,0,1-.41-.09L7.9,11.39a1,1,0,1,1,.81-1.83L16,12.81a1,1,0,0,1-.41,1.91Z'
				fill='currentColor'
				stroke='none'
				strokeLinecap='round'
				strokeLinejoin='round'
				strokeWidth='0'
			></path>
			<path
				d='M17.06,12.43a1,1,0,0,1-.59-.19L10,7.54a1,1,0,1,1,1.17-1.62l6.48,4.69a1,1,0,0,1-.59,1.81Z'
				fill='currentColor'
				stroke='none'
				strokeLinecap='round'
				strokeLinejoin='round'
				strokeWidth='0'
			></path>
			<path
				d='M19,10.48a1,1,0,0,1-.74-.33L12.85,4.21a1,1,0,1,1,1.48-1.34l5.36,5.94A1,1,0,0,1,19,10.48Z'
				fill='currentColor'
				stroke='none'
				strokeLinecap='round'
				strokeLinejoin='round'
				strokeWidth='0'
			></path>
			<path
				d='M21.21,9a1,1,0,0,1-.87-.5l-4-6.92a1,1,0,1,1,1.73-1l4,6.92A1,1,0,0,1,21.21,9Z'
				fill='currentColor'
				stroke='none'
				strokeLinecap='round'
				strokeLinejoin='round'
				strokeWidth='0'
			></path>
		</g>
	</svg>
);
