export const contactDetails = {
	address: {
		street: 'Sandhübel',
		number: '8',
		zip: '66640',
		city: 'Namborn',
		state: 'SL',
		country: 'Germany',
		url: 'https://www.google.com/maps/place/Tobias+W%C3%A4lde+Web+Development/@49.5121983,7.1602089,17z/data=!3m1!4b1!4m5!3m4!1s0x4795eb2c3bc4d2fb:0xe527829ff3b9561f!8m2!3d49.512251!4d7.162313',
		lat: 49.51222,
		lng: 7.16235,
	},
	email: 'tobias.waelde@gmail.com',
	phone: '+49 176 56799893',
};
