import { motion, Transition, Variants } from 'framer-motion';
import { FC, HTMLProps } from 'react';
import clsx from 'clsx';
import twColors from 'tailwindcss/colors';
import colors from '../../../config/colors';
import { icons } from '../../../assets/icons';
import { ButtonType } from '../button/types';
import { useTranslation } from 'next-i18next';

export type SubmitButtonState = 'none' | 'loading' | 'error' | 'success';

const getBackgroundVariants = (isDark: boolean): Variants => {
	const theme = isDark ? 'dark' : 'light';
	return {
		none: {
			backgroundImage: `linear-gradient(to right, ${colors[theme].primary}, ${colors[theme].secondary})`,
		},
		loading: {
			backgroundImage: `linear-gradient(to right, ${colors[theme].primary}, ${colors[theme].secondary})`,
		},
		error: {
			backgroundImage: `linear-gradient(to right, ${twColors.red[500]}, ${twColors.red[600]})`,
		},
		success: {
			backgroundImage: `linear-gradient(to right, ${twColors.green[500]}, ${twColors.green[600]})`,
		},
	};
};

const iconTransition: Transition = {
	duration: 2,
	repeat: Infinity,
	repeatDelay: 0,
	ease: 'linear',
	restSpeed: 0,
};

interface Props extends HTMLProps<HTMLButtonElement> {
	state: SubmitButtonState;
	isDark: boolean;
	className?: string;
}
export const SubmitButton: FC<Props> = (props) => {
	const { isDark, className, type, state, onClick } = props;

	const { t } = useTranslation('common');
	return (
		<div className='flex relative'>
			<motion.button
				type={type as ButtonType}
				onClick={onClick}
				disabled={state === 'loading' || props.disabled}
				animate={state}
				variants={getBackgroundVariants(isDark)}
				transition={{ ease: 'linear', duration: 0.2 }}
				className={clsx(
					'flex items-center',
					'py-2 px-6',
					'rounded-full',
					'ring-0 focus:ring-0 outline-none focus:outline-none',
					'transition-all duration-200',
					'active:shadow-md hover:shadow-xl focus:shadow-xl',
					'bg-gradient-to-r',
					'text-main-bg dark:text-main-bg-dark font-bold',
					className
				)}
			>
				<div className='w-full flex'>
					<motion.div
						className='mr-3'
						variants={{ rotating: { rotate: -360 }, default: { rotate: 0 } }}
						animate={state === 'loading' ? 'rotating' : 'default'}
						transition={state === 'loading' ? iconTransition : undefined}
					>
						<div className='w-6 h-6'>
							{state === 'none' && icons.send}
							{state === 'loading' && icons.refresh}
							{state === 'error' && icons.x}
							{state === 'success' && icons.check}
						</div>
					</motion.div>
				</div>

				<span className='flex-1 whitespace-nowrap'>
					{state === 'none' && t('contact.form.submit.text')}
					{state === 'loading' && t('contact.form.submit.loading')}
					{state === 'error' && t('contact.form.submit.error')}
					{state === 'success' && t('contact.form.submit.success')}
				</span>
			</motion.button>
		</div>
	);
};
