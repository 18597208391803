import Link from 'next/link';
import { FC } from 'react';
import logo from '../../../assets/logo/logo';
import { useScroll } from '../../../contexts/scroll';

export const NavbarBranding: FC = () => {
	const { scrollToTop } = useScroll();

	return (
		<div className='flex-1 z-navbar items-start'>
			<Link
				href='/'
				className='flex flex-row h-full items-center gap-2'
				onClick={() => scrollToTop(true)}
			>
				<div className='w-12 h-12 md:w-16 md:h-16 -my-4'>{logo}</div>
				<div>
					<span className='text-middle dark:text-middle-dark font-extrabold text-xl md:text-2xl'>
						TW
					</span>
				</div>
			</Link>
		</div>
	);
};
