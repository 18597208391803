import { icons } from '../assets/icons';

type SocialMediaItem = {
	name: string;
	url: string;
	icon: JSX.Element;
};

export const SOCIAL_MEDIA: SocialMediaItem[] = [
	{
		name: 'Twitter',
		icon: icons.socialMedia.twitter,
		url: 'https://twitter.com/waelde_tobias',
	},
	{
		name: 'YouTube',
		icon: icons.socialMedia.youtube,
		url: 'https://www.youtube.com/channel/UCwLapDfTUzWpbKONpFVMkdg',
	},
	{
		name: 'LinkedIn',
		icon: icons.socialMedia.linkedin,
		url: 'https://www.linkedin.com/in/tobiaswaelde/',
	},
	{
		name: 'GitLab',
		icon: icons.socialMedia.gitlab,
		url: 'https://gitlab.com/tobiaswaelde',
	},
	{
		name: 'GitHub',
		icon: icons.socialMedia.github,
		url: 'https://github.com/tobiaswaelde',
	},
];
