import { useTheme } from 'next-themes';
import { FC, FormEvent, useEffect, useState } from 'react';
import { icons } from '../../../../../assets/icons';
import * as yup from 'yup';
import { Input } from '../../../../ui/inputs/input';
import { TextArea } from '../../../../ui/inputs/textarea';
import { SubmitButton, SubmitButtonState } from '../../../../ui/button-old/submit-button';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { CaptchaNotice } from './captcha-notice';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useTranslation } from 'next-i18next';
import { http } from 'services/http';

type Props = {
	enabled: boolean;
};
export const Form: FC<Props> = (props) => {
	const isDark = useTheme().resolvedTheme === 'dark';
	const [submitted, setSubmitted] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [state, setState] = useState<SubmitButtonState>('none');
	// const [showCaptchaNotice, setShowCaptchaNotice] = useState<boolean>(false);

	const [name, setName] = useState<string>('');
	const [phone, setPhone] = useState<string>('');
	const [email, setEmail] = useState<string>('');
	const [message, setMessage] = useState<string>('');
	const [valid, setValid] = useState<boolean>(false);

	const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setLoading(true);
		setState('loading');

		try {
			const data = { name, phone, email, message };

			await http.post('/api/contact', { data });

			setState('success');
		} catch (err) {
			console.error(err);
			setState('error');
		} finally {
			setSubmitted(true);
			setLoading(false);
		}
	};

	const handleReset = () => {
		setMessage('');

		setLoading(false);
		setSubmitted(false);
		setState('none');
	};

	const { t } = useTranslation('common');
	return (
		<form
			className='grid grid-cols-1 md:grid-cols-2 gap-6'
			onSubmit={handleSubmit}
			onReset={handleReset}
		>
			<div>
				<Input
					name='fullname'
					placeholder={t('contact.form.fields.name.placeholder') || undefined}
					autoComplete='name'
					max={50}
					icon={icons.user}
					validationSchema={yup
						.string()
						.required(t('contact.form.errors.required') || undefined)
						.max(50, t('contact.form.errors.too-long') || undefined)}
					value={name}
					onChange={(e) => setName(e.currentTarget.value)}
					disabled={loading}
				/>
			</div>
			<div>
				<Input
					name='phone'
					placeholder={t('contact.form.fields.phone.placeholder') || undefined}
					autoComplete='tel'
					max={20}
					icon={icons.phone}
					validationSchema={yup
						.string()
						// .required(t('contact.form.errors.required'))
						.matches(/^\+?\d(\s*\d)+$/, t('contact.form.errors.no-phone-number') || undefined)
						.max(20, t('contact.form.errors.too-long') || undefined)}
					value={phone}
					onChange={(e) => setPhone(e.currentTarget.value)}
					disabled={loading}
				/>
			</div>
			<div className='md:col-span-2'>
				<Input
					name='email'
					placeholder={t('contact.form.fields.email.placeholder') || undefined}
					autoComplete='email'
					max={255}
					icon={icons.email}
					validationSchema={yup
						.string()
						.required(t('contact.form.errors.required') || undefined)
						.email(t('contact.form.errors.no-valid-email') || undefined)
						.max(255, t('contact.form.errors.too-long') || undefined)}
					value={email}
					onChange={(e) => setEmail(e.currentTarget.value)}
					disabled={loading}
				/>
			</div>
			<div className='md:col-span-2'>
				<TextArea
					name='message'
					rows={4}
					placeholder={t('contact.form.fields.message.placeholder') || undefined}
					icon={icons.message}
					minLength={20}
					maxLength={2000}
					validationSchema={yup
						.string()
						.required(t('contact.form.errors.required') || undefined)
						.min(20, t('contact.form.errors.too-short') || undefined)
						.max(2000, t('contact.form.errors.too-long') || undefined)}
					value={message}
					onChange={(e) => setMessage(e.currentTarget.value)}
					disabled={loading}
				/>
			</div>

			{/* {(showCaptchaNotice || props.enabled) && (
				<motion.div
					className='md:col-span-2'
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					transition={{ duration: 0.2 }}
				>
					<CaptchaNotice enabled={props.enabled} />
				</motion.div>
			)} */}

			<div className='md:col-span-2'>
				<div className={clsx('flex flex-col gap-2 items-center', 'md:flex-row md:gap-4')}>
					<SubmitButton
						isDark={isDark}
						type={submitted ? 'reset' : 'submit'}
						state={state}
						disabled={loading || !props.enabled}
					/>

					{state === 'success' && (
						<p className='font-bold text-green-500 text-center md:text-left'>
							{t('contact.form.feedback.success')}
						</p>
					)}
					{state === 'error' && (
						<p className='font-bold text-red-500 text-center md:text-left'>
							{t('contact.form.feedback.error')}
						</p>
					)}
				</div>
			</div>
		</form>
	);
};
