import clsx from 'clsx';
import { FC } from 'react';

type DividerPosition = 'top' | 'bottom';

interface Props {
	divider: JSX.Element;
	position: DividerPosition;
	className?: string;
	svgClassName?: string;
}

export const Divider: FC<Props> = (props) => {
	const { position, divider } = props;

	const top = position === 'top';
	const bottom = position === 'bottom';

	return (
		<div
			className={clsx(
				'absolute w-full',
				'h-6 sm:h-12 md:h-16 lg:h-24',
				{
					'top-0': top,
					'bottom-0': bottom,
				},
				props.className
			)}
		>
			<div
				className={clsx(
					'absolute w-full h-full',
					{ 'bottom-0': top, 'top-0': bottom },
					props.svgClassName
				)}
			>
				{divider}
			</div>
		</div>
	);
};
