import Head from 'next/head';
import { useRouter } from 'next/router';
import { FC } from 'react';

type SeoProps = {
	/** Page title + social media title */
	title?: string | null;
	/** Page description + social media description */
	description?: string | null;
	/** Social media image url */
	imageUrl?: string;
	/** Comma separated page keywords */
	keywords?: string;
	/** Page author */
	author?: string;
	/** Page updated at timestamp */
	updatedAt?: Date;
	/**
	 * Page type (e.g. 'website', 'video.movie', ...)
	 * @default 'website'
	 */
	type?: string;
};

const divier = '•';

export const Seo: FC<SeoProps> = (props) => {
	const { description, imageUrl, keywords, author, updatedAt, type } = props;
	const { asPath } = useRouter();

	const title = props.title
		? `${props.title} ${divier} Tobias Wälde ${divier} Full-Stack Developer`
		: `Tobias Wälde ${divier} Full-Stack Developer`;

	return (
		<Head>
			<title>{title}</title>
			{description && <meta name='description' content={description} />}
			{keywords && <meta name='keywords' content={keywords} />}
			{author && <meta name='author' content={author} />}

			<meta property='og:site_name' content={title} />

			{title && <meta property='og:title' content={title} />}
			{description && <meta property='og:description' content={description} />}
			{imageUrl && (
				<meta
					property='og:image'
					itemProp='image'
					content={process.env.NEXT_PUBLIC_APP_URL + imageUrl}
				/>
			)}
			{updatedAt && <meta property='og:updated_time' content={updatedAt.toISOString()} />}
			<meta property='og:type' content={type || 'website'} />
			<meta property='og:url' content={process.env.NEXT_PUBLIC_APP_URL + asPath} />
		</Head>
	);
};
