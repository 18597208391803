import clsx from 'clsx';
import Link from 'next/link';
import { FC } from 'react';

type Props = {
	href: string;
	title: string;
	className?: string;
};
export const LegalLink: FC<Props> = (props) => {
	const { href, title } = props;

	return (
		<Link href={href} className={clsx('link', props.className)}>
			{title}
		</Link>
	);
};
