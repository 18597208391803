import clsx from 'clsx';
import { FC, HTMLProps } from 'react';

interface Props extends HTMLProps<HTMLDivElement> {
	className?: string;
	style?: React.CSSProperties;
}

export const Card: FC<Props> = (props) => {
	const { className, ...divProps } = props;

	return (
		<div className={clsx('rounded-xl px-6 md:px-12 py-8 md:py-16', className)} {...divProps}>
			{props.children}
		</div>
	);
};
