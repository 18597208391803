import socialMedia from './social-media';
import alertCircle from './alert-circle';
import alertTriangle from './alert-triangle';
import arrowMaximize from './arrow-maximize';
import brightnessHalf from './brightness-half';
import check from './check';
import checks from './checks';
import chevronDown from './chevron-down';
import chevronLeft from './chevron-left';
import chevronRight from './chevron-right';
import chevronUp from './chevron-up';
import deviceFloppy from './device-floppy';
import download from './download';
import email from './email';
import envelope from './envelope';
import externalLink from './external-link';
import invalid from './invalid';
import layoutGrid from './layout-grid';
import link from './link';
import listCheck from './list-check';
import location from './location';
import menu from './menu';
import message from './message';
import moon from './moon';
import mouse from './mouse';
import phone from './phone';
import refresh from './refresh';
import rotateClockwise from './rotate-clockwise';
import send from './send';
import star from './star';
import sun from './sun';
import swipeUp from './swipe-up';
import user from './user';
import valid from './valid';
import x from './x';

export const icons = {
	socialMedia,
	alertCircle,
	alertTriangle,
	arrowMaximize,
	brightnessHalf,
	check,
	checks,
	chevronDown,
	chevronLeft,
	chevronRight,
	chevronUp,
	deviceFloppy,
	download,
	email,
	envelope,
	externalLink,
	invalid,
	layoutGrid,
	listCheck,
	link,
	location,
	menu,
	message,
	moon,
	mouse,
	phone,
	refresh,
	rotateClockwise,
	send,
	star,
	sun,
	swipeUp,
	user,
	valid,
	x,
};
