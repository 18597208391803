import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { FC } from 'react';

type Props = {
	name: string;
	touched: boolean;
	size: number;
	min?: number;
	max?: number;
	isError: boolean;
	error?: string;
};
export const ErrorAndSizeDisplay: FC<Props> = (props) => {
	const { name, touched, size, min, max, isError, error } = props;

	const sizeDisplayVisible = touched && min && max;

	return (
		<AnimatePresence>
			{(isError || sizeDisplayVisible) && (
				<motion.div
					className='w-full flex flex-row'
					initial={{ height: 0, marginTop: 0 }}
					animate={{ height: 'auto', marginTop: '0.25rem' }}
					exit={{ height: 0, marginTop: 0 }}
				>
					{isError && (
						<motion.p
							className='text-sm text-red-500 dark:text-red-500 mx-3 flex-1'
							id={`${name}-error`}
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							exit={{ opacity: 0 }}
						>
							{error}
						</motion.p>
					)}
					{!isError && <div className='flex-1' />}

					{sizeDisplayVisible && (
						<motion.p
							className={clsx('text-xs text-typography-light text-right mr-3 whitespace-nowrap', {
								'text-red-500 dark:text-red-500':
									(min ? size < min : false) || (max ? size > max : false),
								'text-green-600 dark:text-green-600':
									(min ? size >= min : true) && (max ? size <= max : true),
							})}
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							exit={{ opacity: 0 }}
						>
							{size} / {max}
						</motion.p>
					)}
				</motion.div>
			)}
		</AnimatePresence>
	);
};
