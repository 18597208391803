export const APP_URL = String(process.env.NEXT_PUBLIC_APP_URL) ?? '';
export const API_URL = String(process.env.NEXT_PUBLIC_API_URL) ?? '';
export const MAPBOX_API_KEY = String(process.env.MAPBOX_API_KEY).trim() ?? '';
export const GOOGLE_RECAPTCHAV3_SECRET_KEY =
	String(process.env.GOOGLE_RECAPTCHAV3_SECRET_KEY).trim() ?? '';
export const GOOGLE_RECAPTCHAV3_PUBLIC_KEY =
	String(process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHAV3_PUBLIC_KEY).trim() ?? '';

export const GITLAB_ACCESS_TOKEN = String(process.env.GITLAB_ACCESS_TOKEN);
export const GITLAB_EMAIL = String(process.env.GITLAB_EMAIL);
export const GITLAB_PROJECT_BLACKLIST = [8641662];

export const SMTP_HOST = String(process.env.SMTP_HOST);
export const SMTP_PORT = Number(process.env.SMTP_PORT) ?? 465;
export const SMTP_USERNAME = String(process.env.SMTP_USERNAME);
export const SMTP_PASSWORD = String(process.env.SMTP_PASSWORD);
export const SMTP_SECURE = Boolean(process.env.SMTP_SECURE) ?? true;
export const CONTACT_RECEIVER = String(process.env.CONTACT_RECEIVER);
