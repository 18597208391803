type NavItem = {
	path: string;
	titleKey: string;
	hasBackground?: boolean;
};

export const NAVIGATION: NavItem[] = [
	{ path: '/', titleKey: 'navbar.navigation.home', hasBackground: true },
	{ path: '/resume', titleKey: 'navbar.navigation.resume' },
	{ path: '/skills', titleKey: 'navbar.navigation.skills' },
	{ path: '/projects', titleKey: 'navbar.navigation.projects' },
	// { path: '/blog', titleKey: 'Blog' },
];
