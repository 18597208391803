import { useTranslation } from 'next-i18next';
import { FC } from 'react';
import { LegalLink } from './legal-link';

export const LegalFooterSection: FC = () => {
	const divider = <span> | </span>;

	const { t } = useTranslation('common');
	return (
		<div className='flex justify-center pb-2'>
			<div className='text-xs'>
				<LegalLink
					href='/legal/legal-disclosure'
					title={t('legal.legal-disclosure')}
					className='text-typography-secondary dark:text-typography-secondary-dark'
				/>
				{divider}
				<LegalLink
					href='/legal/privacy-policy'
					title={t('legal.privacy-policy')}
					className='text-typography-secondary dark:text-typography-secondary-dark'
				/>
			</div>
		</div>
	);
};
