import { FC, Fragment, PropsWithChildren } from 'react';
import { Dialog, Transition } from '@headlessui/react';

interface Props {
	open: boolean;
	onClose: () => void;
	className?: string;
}
export const Modal: FC<PropsWithChildren<Props>> = (props) => {
	return (
		<Transition appear show={props.open} as={Fragment}>
			<Dialog as='div' onClose={props.onClose} className='fixed inset-0 z-[40]'>
				<Transition.Child
					as={Fragment}
					enter='ease-out duration-300'
					enterFrom='opacity-0'
					enterTo='opacity-100'
					leave='ease-in duration-200'
					leaveFrom='opacity-100'
					leaveTo='opacity-0'
				>
					<div className='fixed inset-0 bg-black bg-opacity-80 backdrop-filter backdrop-blur-sm' />
				</Transition.Child>

				<div className='fixed inset-0 overflow-y-auto z-[41]'>
					<div className='flex min-h-full items-center justify-center p-4'>
						<Transition.Child
							as={Fragment}
							enter='ease-out duration-300'
							enterFrom='opacity-0 scale-95'
							enterTo='opacity-100 scale-100'
							leave='ease-in duration-200'
							leaveFrom='opacity-100 scale-100'
							leaveTo='opacity-0 scale-95'
						>
							<Dialog.Panel>{props.children}</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	);
};
