import Link from 'next/link';
import { FC } from 'react';
import { AnchorIconButtonProps } from './types';
import { getButtonClassName, GetButtonClassNameProps } from './util';
import { ButtonBefore } from './_button-before';
import { IconButtonContent } from './_icon-button-content';

type Props = AnchorIconButtonProps;

export const AnchorIconButton: FC<Props> = (props) => {
	//#region props
	const {
		// button base props
		variant,
		noShadow,
		noHover,
		noFocus,
		noActive,
		rounded,
		contentClassName,
		textGradient,
		ringClassName,
		backgroundClassName,
		label,
		// icon props
		icon,
		iconClassName,
		size,
		padding,
		tooltip,
		// link props
		href,
		as,
		passHref,
		prefetch,
		replace,
		scroll,
		shallow,
		locale,
		legacyBehavior,
		onMouseEnter,
		onTouchStart,
		onClick,
		// a props
		className,
		...buttonProps
	} = props;
	const linkProps = {
		href,
		as,
		passHref,
		prefetch,
		replace,
		scroll,
		shallow,
		locale,
		legacyBehavior,
		onMouseEnter,
		onTouchStart,
		onClick,
	};
	const getButtonClassNameProps: GetButtonClassNameProps = {
		variant,
		noShadow,
		noHover,
		noFocus,
		noActive,
		rounded,
		fullWidth: false,
		className,
	};
	//#endregion

	return (
		<Link
			{...linkProps}
			className={getButtonClassName(getButtonClassNameProps)}
			aria-label={label}
			{...buttonProps}
		>
			<ButtonBefore
				variant={variant}
				ringClassName={ringClassName}
				backgroundClassName={backgroundClassName}
			/>
			<IconButtonContent
				icon={icon}
				size={size!}
				padding={padding!}
				contentClassName={contentClassName}
				textGradient={textGradient}
				iconClassName={iconClassName}
				tooltip={tooltip}
			/>
		</Link>
	);
};

AnchorIconButton.displayName = 'AnchorIconButton';
AnchorIconButton.defaultProps = {
	variant: 'default',
	noShadow: false,
	noHover: false,
	noFocus: true,
	noActive: false,
	rounded: true,
	ringClassName: 'ring-background dark:ring-background-dark',
	backgroundClassName: 'bg-background dark:bg-background-dark',
	textGradient: true,
	size: 12,
	padding: 2.5,
	href: '/',
};
