import clsx from 'clsx';
import { FC, PropsWithChildren } from 'react';
import { Navbar } from './navbar';
import { ScrollToTopButton } from './util/scroll-to-top-button';

type Props = {
	contactForm?: boolean;
	noTopPadding?: boolean;
	staticContactButton?: boolean;
};

export const LayoutWrapper: FC<PropsWithChildren<Props>> = (props) => {
	return (
		<div className='relative'>
			<div>
				<Navbar contactForm={props.contactForm} staticContactButton={props.staticContactButton} />
				<ScrollToTopButton />

				<main
					className={clsx('relative h-full', {
						'pt-0': props.noTopPadding,
						'mt-[52px] xs:mt-[76px]': !props.noTopPadding,
					})}
				>
					<div className='w-full h-full z-20'>{props.children}</div>
				</main>
			</div>
		</div>
	);
};
