import clsx from 'clsx';
import {
	ChangeEventHandler,
	FocusEventHandler,
	ForwardedRef,
	forwardRef,
	HTMLProps,
	useCallback,
	useEffect,
	useState,
} from 'react';
import * as yup from 'yup';
import { yupErrorToErrorObject } from '../../../../util/validation';
import { InputIcon } from '../input-icon';
import { ValidationIcon } from '../validation-icon';
import { ErrorAndSizeDisplay } from './error-size-display';

type Props = {
	name: string;
	icon?: any;
	error?: string;
	validationSchema?: yup.AnySchema;
	validIcon?: any;
	invalidIcon?: any;
} & HTMLProps<HTMLTextAreaElement>;

export const TextArea = forwardRef((props: Props, ref: ForwardedRef<HTMLTextAreaElement>) => {
	const { type, validationSchema, icon, validIcon, invalidIcon, ...inputProps } = props;

	//#region validation
	const [error, setError] = useState<string | undefined>(undefined);
	const [touched, setTouched] = useState<boolean>(false);
	const [valid, setValid] = useState<boolean>(false);

	const validate = useCallback(
		(value: string) => {
			if (!validationSchema) return; // no schema -> no validation

			// create yup schema
			const schema = yup.object().shape({
				[props.name]: validationSchema,
			});

			// validate
			schema
				.validate({ [props.name]: value }, { abortEarly: false })
				.then(() => {
					setError(undefined);
					setValid(true);
				})
				.catch((err: yup.ValidationError) => {
					const errorObject = yupErrorToErrorObject(err);
					setError(errorObject[props.name].join('; ') || '');
					setValid(false);
				});
		},
		[props.name, validationSchema]
	);

	// validate on value change
	useEffect(() => {
		if (typeof props.value === 'string' && props.value !== '') {
			setTouched(true);
			validate(props.value);
		}
	}, [props.value, validate]);
	//#endregion
	//#region input event handler
	const handleChange: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
		// call default on change handler
		if (props.onChange) {
			props.onChange(e);
		}

		if (touched) {
			validate(e.currentTarget.value);
		}
	};

	const handleFocusLeave: FocusEventHandler<HTMLTextAreaElement> = (e) => {
		setTouched(true);
		validate(e.currentTarget.value);
	};
	//#endregion

	const isError = props.error !== undefined || error !== undefined;
	const isValid = validationSchema && touched && valid && !isError;

	return (
		<div className='relative flex flex-col items-start'>
			<div className='relative w-full'>
				<div>
					<textarea
						ref={ref}
						className={clsx(
							'select-text',
							'relative block',
							'w-full h-full px-6 pr-9 rounded-2xl',
							'border-2 border-transparent font-bold',
							'bg-form-bg dark:bg-form-bg-dark',
							'text-typography dark:text-typography-dark',
							'ring-0 outline-none',
							'transition-border duration-200',
							'focus:shadow-lg focus:ring-0 focus:outline-none', // focus
							'disabled:text-typography-secondary dark:disabled:text-typography-secondary-dark', // disabled
							'focus:border-middle dark:focus:border-middle-dark',
							'box-border',
							{
								'pl-2': icon === undefined,
								'pl-9': icon !== undefined,
								'pr-9': isValid || isError,
								'focus:border-2 focus:border-middle dark:focus:border-middle-dark':
									!isError && !isValid,
								'border-1 border-red-500': isError,
								'border-1 border-green-600': isValid,
							}
						)}
						{...inputProps}
						id={props.name}
						style={{ ...props.style, resize: 'none', overflow: 'hidden' }}
						aria-invalid={isError ? 'true' : 'false'}
						aria-describedby={`${props.name}-error`}
						onChange={handleChange}
						onBlur={handleFocusLeave}
					/>
				</div>
				{icon && <InputIcon icon={icon} align='top' />}
				<ValidationIcon
					isValid={isValid}
					isError={isError}
					validIcon={validIcon}
					invalidIcon={invalidIcon}
				/>
			</div>
			<ErrorAndSizeDisplay
				name={props.name}
				touched={touched}
				size={(props.value as string).length}
				min={props.minLength}
				max={props.maxLength}
				isError={isError}
				error={error || props.error}
			/>
		</div>
	);
});
TextArea.displayName = 'TextArea';
