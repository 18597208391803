import { motion, Transition, Variants } from 'framer-motion';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { Dispatch, FC, SetStateAction, useEffect, useRef } from 'react';
import { icons } from '../../../assets/icons';
import { Button } from '../../ui/button/button';
import { IconButton } from '../../ui/button/icon-button';

const transition: Transition = { duration: 0.25 };

const buttonVariants = (buttonWidth: number): Variants => ({
	open: {
		width: buttonWidth,
		opacity: 1,
	},
	closed: {
		width: 0,
		opacity: 0,
	},
});

type Props = {
	redirectHome?: boolean;
	buttonWidth: number;
	setButtonWidth: Dispatch<SetStateAction<number>>;
	slideLeft: boolean;
	static?: boolean;
};
export const ContactButton: FC<Props> = (props) => {
	const router = useRouter();
	const contactButtonRef = useRef<HTMLDivElement>(null);
	const { t } = useTranslation('common');

	useEffect(() => {
		if (!contactButtonRef.current) return;
		props.setButtonWidth(contactButtonRef.current.scrollWidth);
	}, [props, contactButtonRef]);

	const handleButtonClick = () => {
		if (props.redirectHome) {
			router.push('/#contact');
		} else {
			router.push('#contact');
		}
	};

	const content = (
		<>
			<div className='md:hidden ml-2'>
				<IconButton
					icon={icons.message}
					label={t('navbar.contact-button.label') || undefined}
					variant='outlined'
					size={10}
					padding={2}
					onClick={handleButtonClick}
				/>
			</div>
			<div className='hidden md:block ml-2'>
				<Button noShadow variant='contained' onClick={handleButtonClick}>
					{t('navbar.contact-button.text')}
				</Button>
			</div>
		</>
	);

	if (props.static) {
		return <div className='flex'>{content}</div>;
	} else {
		return (
			<motion.div
				ref={contactButtonRef}
				className='flex'
				variants={buttonVariants(props.buttonWidth)}
				initial={props.static ? 'open' : 'closed'}
				exit={props.static ? 'open' : 'closed'}
				animate={props.static || props.slideLeft ? 'open' : 'closed'}
				transition={transition}
			>
				{content}
			</motion.div>
		);
	}
};
