import clsx from 'clsx';
import { FC, PropsWithChildren } from 'react';
import classes from './index.module.scss';

export type HeaderType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

interface Props {
	type: HeaderType;
	id?: string;
	className?: string;
	notBold?: boolean;
	noMargin?: boolean;
	component?: HeaderType;
}

export const Header: FC<PropsWithChildren<Props>> = (props) => {
	const className = clsx(
		{ 'font-bold': !props.notBold, anchor: props.id },
		classes[props.type],
		props.className
	);

	const component = props.component ?? props.type;

	switch (component) {
		case 'h1':
			return (
				<h1 className={className} id={props.id}>
					{props.children}
				</h1>
			);
		case 'h2':
			return (
				<h2 className={className} id={props.id}>
					{props.children}
				</h2>
			);
		case 'h3':
			return (
				<h3 className={className} id={props.id}>
					{props.children}
				</h3>
			);
		case 'h4':
			return (
				<h4 className={className} id={props.id}>
					{props.children}
				</h4>
			);
		case 'h5':
			return (
				<h5 className={className} id={props.id}>
					{props.children}
				</h5>
			);
		case 'h6':
			return (
				<h6 className={className} id={props.id}>
					{props.children}
				</h6>
			);
	}
};
