import clsx from 'clsx';
import { motion, Variants } from 'framer-motion';
import { FC, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useScroll } from '../../../contexts/scroll';
import { IconButton } from '../../ui/button/icon-button';
import { icons } from '../../../assets/icons';
import { useTranslation } from 'next-i18next';

const buttonVariants: Variants = {
	visible: { scale: 1 },
	invisible: { scale: 0 },
};

export const ScrollToTopButton: FC = () => {
	const scroll = useScroll();
	const router = useRouter();
	const [visible, setVisible] = useState<boolean>(false);

	useEffect(() => {
		setVisible(scroll.scrollTop > window.innerHeight / 2);
	}, [scroll.scrollTop]);

	const handleScrollTop = () => {
		router.replace(router.pathname);
		scroll.scrollToTop(true);
	};

	const { t } = useTranslation('common');
	return (
		<motion.div
			className={clsx('fixed z-navbar', 'bottom-0 right-0 m-4 sm:m-8 rounded-full')}
			variants={buttonVariants}
			initial='invisible'
			animate={visible ? 'visible' : 'invisible'}
		>
			<IconButton
				icon={icons.chevronUp}
				variant='contained'
				noActive
				noHover
				noFocus
				onClick={handleScrollTop}
				className='shadow-xl'
				label={t('labels.scroll-to-top') || undefined}
			/>
		</motion.div>
	);
};
