import { FC, PropsWithChildren } from 'react';
import { ContactDetailsItem } from './item';

const ContactDetails: FC<PropsWithChildren<{}>> = (props) => {
	return <div className='grid mt-6 gap-4'>{props.children}</div>;
};

export default Object.assign(ContactDetails, {
	Item: ContactDetailsItem,
});
