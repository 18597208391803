const colors = {
	light: {
		mainBg: '#ffffff',
		background: '#f6f7fb',
		formBg: '#ebebf5',
		primary: '#647DED',
		secondary: '#7E53AC',
		middle: '#7168CD',
		typography: '#343434',
		typographySecondary: '#787878',
	},
	dark: {
		mainBg: '#1d222a',
		background: '#222831',
		formBg: '#14181d', //'#393E46',
		primary: '#EF9615',
		secondary: '#FE5857',
		middle: '#F77736',
		typography: '#e9eaea',
		typographySecondary: '#91969c',
	},
};

module.exports = colors;
