import clsx from 'clsx';
import { useRouter } from 'next/router';
import { FC, useEffect, useState } from 'react';
import { NAVIGATION } from '../../../config/navigation';
import { useScroll } from '../../../contexts/scroll';
import { Container } from '../container';
import { NavbarBranding } from './branding';
import { ContactButton } from './contact-button';
import { Navigation } from './navigation';

const bgBackgroundPathnames = NAVIGATION.filter((x) => x.hasBackground).map((x) => x.path);

type Props = {
	contactForm?: boolean;
	staticContactButton?: boolean;
};
export const Navbar: FC<Props> = (props) => {
	const { pathname } = useRouter();
	const { scrollTop } = useScroll();

	const [buttonWidth, setButtonWidth] = useState<number>(0);

	//#region background color
	const [isBgBackground, setIsBgBackground] = useState<boolean>(
		bgBackgroundPathnames.includes(pathname)
	);
	const elevated = scrollTop > 100;

	useEffect(() => {
		const bgBackground = bgBackgroundPathnames.includes(pathname);
		setIsBgBackground(bgBackground);
	}, [pathname, elevated]);
	//#endregion

	return (
		<div
			className={clsx(
				'fixed top-0 left-0 w-full z-navbar py-1.5 xs:py-4',
				'transition-all duration-200',
				'whitespace-nowrap',
				{
					'bg-background dark:bg-background-dark': isBgBackground && !elevated,
					'bg-main-bg dark:bg-main-bg-dark': (isBgBackground && elevated) || !isBgBackground,
					'shadow-xl dark:shadow-[rgba(0,0,0,.6)]': elevated,
				}
			)}
		>
			<Container className='flex flex-row'>
				<NavbarBranding />
				<div className='flex flex-row relative z-navbar'>
					<Navigation
						buttonWidth={buttonWidth}
						slideLeft={props.staticContactButton || !isBgBackground || elevated}
						staticContactButton={props.staticContactButton}
					/>
					<ContactButton
						redirectHome={!props.contactForm}
						buttonWidth={buttonWidth}
						setButtonWidth={setButtonWidth}
						slideLeft={props.staticContactButton || !isBgBackground || elevated}
						static={props.staticContactButton}
					/>
				</div>
			</Container>
		</div>
	);
};
