import { FC, useEffect, useState } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { GOOGLE_RECAPTCHAV3_PUBLIC_KEY } from '../../../../../config/env';
import { useCookie } from '@tobiaswaelde/cookie-manager/context';
import { Form } from './form';

export const ContactForm: FC = () => {
	// const cookie = useCookie();
	// const [isEnabled, setEnabled] = useState<boolean>(cookie.checkServiceEnabled('recaptcha'));

	// useEffect(() => {
	// 	setEnabled(cookie.checkServiceEnabled('recaptcha'));

	// 	const removeEventListener = cookie.addEventListener('change', (name, enabled) => {
	// 		if (name === 'recaptcha') {
	// 			setEnabled(enabled === true);
	// 		}
	// 	});
	// 	return () => removeEventListener();
	// }, [cookie, cookie.addEventListener]);

	// if (isEnabled) {
	// 	return (
	// 		<GoogleReCaptchaProvider reCaptchaKey={GOOGLE_RECAPTCHAV3_PUBLIC_KEY}>
	// 			<Form enabled={isEnabled} />
	// 		</GoogleReCaptchaProvider>
	// 	);
	// } else {
	// }
	return <Form enabled={true} />;
};
