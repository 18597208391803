import { FC, useState } from 'react';
import { motion, Transition, Variants } from 'framer-motion';
import { NAVIGATION } from '../../../config/navigation';
import { ThemeToggle } from './theme-toggle';
import { MobileMenu } from './mobile-menu';
import { AnchorButton } from '../../ui/button/anchor-button';
import { IconButton } from '../../ui/button/icon-button';
import { icons } from '../../../assets/icons';
import { useTranslation } from 'next-i18next';

const transition: Transition = { duration: 0.25 };

const navigationVariants = (buttonWidth: number): Variants => ({
	open: {
		right: buttonWidth,
	},
	closed: {
		right: 0,
	},
});

type Props = {
	buttonWidth: number;
	slideLeft: boolean;
	staticContactButton?: boolean;
};
export const Navigation: FC<Props> = (props) => {
	const [menuOpen, setMenuOpen] = useState<boolean>(false);
	const { t } = useTranslation('common');

	const content = (
		<>
			<div className='flex lg:hidden flex-row'>
				<IconButton
					icon={icons.menu}
					variant='flat'
					onClick={() => setMenuOpen(true)}
					label={t('navbar.menu-button.label') || undefined}
				/>
				<MobileMenu open={menuOpen} setOpen={setMenuOpen} />
			</div>
			<div className='hidden lg:flex'>
				{NAVIGATION.map((item, i) => (
					<AnchorButton key={i} href={item.path} variant='flat' noActive noFocus>
						{t(item.titleKey)}
					</AnchorButton>
				))}
			</div>

			{/* <ThemeToggle /> */}
		</>
	);

	if (props.staticContactButton) {
		return <div className='flex h-full items-center relative'>{content}</div>;
	} else {
		return (
			<>
				<motion.div
					className='flex h-full items-center absolute'
					variants={navigationVariants(props.buttonWidth)}
					initial={props.staticContactButton ? 'open' : 'closed'}
					exit={props.staticContactButton ? 'open' : 'closed'}
					animate={props.staticContactButton || props.slideLeft ? 'open' : 'closed'}
					transition={transition}
				>
					{content}
				</motion.div>
			</>
		);
	}
};
