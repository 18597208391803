import clsx from 'clsx';
import { ButtonVariant } from './types';

export type GetButtonClassNameProps = {
	variant?: ButtonVariant;
	noShadow?: boolean;
	noHover?: boolean;
	noFocus?: boolean;
	noActive?: boolean;
	rounded?: boolean;
	fullWidth?: boolean;
	className?: string;
};
export function getButtonClassName(props: GetButtonClassNameProps) {
	return clsx(
		'btn',
		'flex',
		{
			[`btn-${props.variant}`]: props.variant,
			'btn-shadow': !props.noShadow,
			'btn-hover': !props.noHover,
			'btn-focus': !props.noFocus,
			'btn-active': !props.noActive,
			'btn-rounded': props.rounded,
			'w-full': props.fullWidth,
		},
		props.className
	);
}
