import github from './github';
import gitlab from './gitlab';
import instagram from './instagram';
import linkedin from './linkedin';
import stackoverflow from './stackoverflow';
import twitter from './twitter';
import youtube from './youtube';

export default {
	github,
	gitlab,
	instagram,
	linkedin,
	stackoverflow,
	twitter,
	youtube,
};
