import clsx from 'clsx';
import { FC } from 'react';
import { ButtonVariant } from './types';

type Props = {
	variant?: ButtonVariant;
	ringClassName?: string;
	backgroundClassName?: string;
};
export const ButtonBefore: FC<Props> = (props) => {
	const { variant, ringClassName, backgroundClassName } = props;
	return (
		<div
			className={clsx('before', {
				[ringClassName!]: ringClassName && (variant === 'default' || variant === 'flat'),
				[backgroundClassName!]: backgroundClassName && variant !== 'flat',
			})}
		/>
	);
};
