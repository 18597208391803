import axios from 'axios';
import { APP_URL } from './../config/env';
import { API_URL } from '../config/env';

const api = axios.create({
	baseURL: API_URL,
});

const http = axios.create({
	baseURL: APP_URL,
});

export { api, http };
