import clsx from 'clsx';
import { FC } from 'react';

type IconAlignment = 'top' | 'center' | 'bottom';

type Props = {
	icon: JSX.Element;
	align?: IconAlignment;
};
export const InputIcon: FC<Props> = (props) => {
	const { align } = props;

	return (
		<div
			className={clsx('absolute inset-y-0 left-0 pl-3 flex pointer-events-none', {
				'pt-3 items-start': align === 'top',
				'items-center': align === 'center',
				'pb-3 items-end': align === 'bottom',
			})}
		>
			<div className='w-5 h-5 text-middle dark:text-middle-dark'>{props.icon}</div>
		</div>
	);
};
InputIcon.defaultProps = {
	align: 'center',
};
