import { useTheme } from 'next-themes';
import { useRouter } from 'next/router';
import { FC, useEffect, useRef } from 'react';
import colors from '../../../../config/colors';
import { useScroll } from '../../../../contexts/scroll';
import dynamic from 'next/dynamic';
import { DividerTop } from './divider-top';
import { Container } from '../../../layout/container';
import { Card } from '../../../ui/card';
import ContactDetails from './contact-details';
import Obfuscate from 'react-obfuscate';
import { contactDetails } from '../../../../data/contact-details';
import { icons } from '../../../../assets/icons';
import { Header } from '../../../ui/typography/header';
import { ContactForm } from './contact-form';
import { useTranslation } from 'next-i18next';

const Map = dynamic(() => import('./map'), {
	ssr: false,
	loading: () => <div>Loading</div>,
});

export const ContactSection: FC = () => {
	const isDark = useTheme().resolvedTheme === 'dark';

	//#region scroll to contact
	const router = useRouter();
	const { scrollRef } = useScroll();
	const contactRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (router.asPath.indexOf('#contact') !== -1 && scrollRef.current) {
			const scrollTop = scrollRef.current.view.scrollTop;
			const top = contactRef.current!.getBoundingClientRect().top - 72 + scrollTop;

			scrollRef.current.view.scroll({ top: top, behavior: 'smooth' });
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [router.asPath]);
	//#endregion

	const address = `${contactDetails.address.country}, ${contactDetails.address.zip} ${contactDetails.address.city}`;

	const { t } = useTranslation('common');
	return (
		<div className='w-full pt-96 pb-32 relative bg-gradient-to-r from-primary dark:from-primary-dark to-secondary dark:to-secondary-dark'>
			<div
				className='absolute w-full top-0 z-0 pt-24'
				style={{
					height: 550,
					backgroundColor: isDark ? colors.dark.background : colors.light.background,
				}}
			>
				{/* @ts-ignore */}
				<Map isDark={isDark} />
				<DividerTop />
			</div>

			<Container className='relative top-0 left-0 right-0 z-10'>
				<Card className='bg-main-bg dark:bg-main-bg-dark shadow-xl dark:shadow-[rgba(0,0,0,.5)]'>
					<div className='grid grid-cols-1 gap-8 lg:grid-cols-2'>
						<div>
							<Header type='h3' className='text-typography dark:text-typography-dark'>
								{t('contact.details.title')}
							</Header>
							<p className='text-justify text-typography-secondary dark:text-typography-secondary-dark'>
								{t('contact.details.description')}
							</p>
							<ContactDetails>
								<ContactDetails.Item icon={icons.email}>
									<Obfuscate
										email={contactDetails.email}
										target='_blank'
										rel='noopener noreferrer canonical'
									/>
								</ContactDetails.Item>
								<ContactDetails.Item icon={icons.location}>
									<Obfuscate
										href={contactDetails.address.url}
										target='_blank'
										rel='noopener noreferrer canonical'
									>
										{address}
									</Obfuscate>
								</ContactDetails.Item>
								<ContactDetails.Item icon={icons.phone}>
									<Obfuscate tel={contactDetails.phone} />
								</ContactDetails.Item>
							</ContactDetails>
						</div>
						<div ref={contactRef}>
							<Header type='h3' className='text-typography dark:text-typography-dark'>
								{t('contact.form.title')}
							</Header>
							<ContactForm />
						</div>
					</div>
				</Card>
			</Container>
		</div>
	);
};
