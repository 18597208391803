import { useTranslation } from 'next-i18next';
import { FC } from 'react';
import { SOCIAL_MEDIA } from '../../../../config/social-media';
import { Container } from '../../../layout/container';
import { AnchorIconButton } from '../../../ui/button/anchor-icon-button';

export const SocialMediaSection: FC = () => {
	const { t } = useTranslation('common');
	return (
		<Container className='py-12'>
			<p className='font-poppins text-center text-typography dark:text-typography-dark'>
				{t('social-media.title')}
			</p>

			<div className='flex flex-row justify-center pt-6 gap-4'>
				{SOCIAL_MEDIA.map((x, i) => (
					<AnchorIconButton
						key={i}
						href={x.url}
						target='_blank'
						rel='noopener noreferrer canonical'
						icon={x.icon}
						variant='outlined'
						className='has-tooltip relative text-typography-secondary dark:text-typography-secondary-dark'
						tooltip={x.name}
						label={x.name}
					/>
				))}
			</div>
		</Container>
	);
};
