import clsx from 'clsx';
import { FC, PropsWithChildren } from 'react';

type Props = {
	className?: string;
	fullWidth?: boolean;
};
export const Container: FC<PropsWithChildren<Props>> = (props) => {
	return (
		<div
			className={clsx(
				{
					'max-w-screen-2xl': props.fullWidth !== true,
				},
				'mx-auto px-4 md:px-6 lg:px-8',
				props.className
			)}
		>
			{props.children}
		</div>
	);
};
Container.defaultProps = {
	fullWidth: false,
};
