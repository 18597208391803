import { useTheme } from 'next-themes';
import { FC } from 'react';
import { Divider } from '../../../ui/divider';

export const DividerTop: FC = () => {
	const isDark = useTheme().resolvedTheme === 'dark';

	const shadowMatrix = isDark
		? undefined
		: '0 0 0 0 0.368627 0 0 0 0 0.368627 0 0 0 0 0.368627 0 0 0 0.745 0';
	const leftColor = isDark ? '#1d222a' : '#ffffff';
	const rightColor = isDark ? '#1d222a' : '#ffffff';

	const divider = (
		<svg
			viewBox='0 0 1440 311'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			className='w-full h-full'
			preserveAspectRatio='none'
		>
			<g filter='url(#filter0_d_12_3)'>
				<path
					d='M0 109.894L48 132.817C96 155.397 192 201.758 288 224.338C384 247.261 480 247.261 576 219.788C672 192.314 768 137.367 864 137.367C960 137.367 1056 192.314 1152 215.237C1248 237.817 1344 229.232 1392 224.338L1440 219.788V0H1392C1344 0 1248 0 1152 0C1056 0 960 0 864 0C768 0 672 0 576 0C480 0 384 0 288 0C192 0 96 0 48 0H0V109.894Z'
					fill='url(#paint0_linear_12_3)'
				/>
			</g>
			<defs>
				<filter
					id='filter0_d_12_3'
					x='-70'
					y='-70'
					width='1580'
					height='381'
					filterUnits='userSpaceOnUse'
					colorInterpolationFilters='sRGB'
				>
					<feFlood floodOpacity='0' result='BackgroundImageFix' />
					<feColorMatrix
						in='SourceAlpha'
						type='matrix'
						values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
						result='hardAlpha'
					/>
					<feOffset />
					<feGaussianBlur stdDeviation='35' />
					<feColorMatrix type='matrix' values={shadowMatrix} />
					<feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_12_3' />
					<feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_12_3' result='shape' />
				</filter>
				<linearGradient
					id='paint0_linear_12_3'
					x1='0'
					y1='0'
					x2='1440'
					y2='5.45296e-06'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor={leftColor} />
					<stop offset='1' stopColor={rightColor} />
				</linearGradient>
			</defs>
		</svg>
	);

	return <Divider position='top' divider={divider} className='z-20' />;
};
