import clsx from 'clsx';
import { FC, Dispatch, SetStateAction } from 'react';
import { Modal } from '../../ui/modal';
import { Header } from '../../ui/typography/header';
import { NAVIGATION } from '../../../config/navigation';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';

interface Props {
	open: boolean;
	setOpen: Dispatch<SetStateAction<boolean>>;
}

export const MobileMenu: FC<Props> = (props) => {
	const { t } = useTranslation('common');
	return (
		<Modal open={props.open} onClose={() => props.setOpen(false)}>
			<div className='flex justify-center'>
				<Header
					type='h3'
					className={clsx(
						'from-primary dark:from-primary-dark to-secondary dark:to-secondary-dark bg-clip-text text-transparent',
						'text-center -mt-2 bg-gradient-to-r'
					)}
				>
					{t('navbar.menu')}
				</Header>
			</div>

			<div className='flex flex-col items-center gap-2 text-white'>
				{NAVIGATION.map((item, i) => {
					return (
						<Link
							key={i}
							href={item.path}
							className='text-lg md:text-xl py-1'
							onClick={() => props.setOpen(false)}
						>
							{t(item.titleKey)}
						</Link>
					);
				})}
			</div>
		</Modal>
	);
};
