{
	/* <svg viewBox="0 0 140 140" height="140" width="140" xmlns="http://www.w3.org/2000/svg"><g transform="matrix(5.833333333333333,0,0,5.833333333333333,0,0)"><path d="M17.167,16.162a2.5,2.5,0,0,0-3.535,0l-.385.385a46.837,46.837,0,0,1-5.789-5.79l.386-.384a2.506,2.506,0,0,0,0-3.536L5.721,4.715a2.5,2.5,0,0,0-3.535,0L1.022,5.88a3.509,3.509,0,0,0-.442,4.4A46.906,46.906,0,0,0,13.722,23.424a3.542,3.542,0,0,0,4.4-.442l1.165-1.164a2.5,2.5,0,0,0,0-3.535Z" fill="currentColor" stroke="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="0"></path><path d="M11.5.007a1,1,0,0,0,0,2A10.512,10.512,0,0,1,22,12.507a1,1,0,1,0,2,0A12.515,12.515,0,0,0,11.5.007Z" fill="currentColor" stroke="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="0"></path><path d="M11.5,6.007a6.508,6.508,0,0,1,6.5,6.5,1,1,0,1,0,2,0,8.51,8.51,0,0,0-8.5-8.5,1,1,0,0,0,0,2Z" fill="currentColor" stroke="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="0"></path><path d="M11.5,10.007a2.5,2.5,0,0,1,2.5,2.5,1,1,0,0,0,2,0,4.505,4.505,0,0,0-4.5-4.5,1,1,0,0,0,0,2Z" fill="currentColor" stroke="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="0"></path></g></svg> */
}

export default (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		className='icon icon-tabler icon-tabler-phone'
		viewBox='0 0 24 24'
		strokeWidth='1.5'
		stroke='currentColor'
		fill='none'
		strokeLinecap='round'
		strokeLinejoin='round'
	>
		<path stroke='none' d='M0 0h24v24H0z' fill='none' />
		<path d='M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2' />
	</svg>
);
