import clsx from 'clsx';
import { FC } from 'react';
import { ButtonTooltip } from './_tooltip';

type Props = {
	icon: JSX.Element;
	size: number;
	padding: number;
	contentClassName?: string;
	textGradient?: boolean;
	iconClassName?: string;
	tooltip?: string;
};
export const IconButtonContent: FC<Props> = (props) => {
	const { icon, size, padding, tooltip } = props;

	const iconSize = size - 2 * padding;
	const buttonPadding = `p-${padding}`;
	const iconWidth = `w-${iconSize}`;
	const iconHeight = `h-${iconSize}`;

	return (
		<div className={clsx('content p-2.5', buttonPadding, props.contentClassName)}>
			<div
				className={clsx(
					'icon w-8 h-8',
					{ 'text-gradient': props.textGradient },
					iconWidth,
					iconHeight,
					props.iconClassName
				)}
			>
				{icon}
			</div>

			{tooltip && <ButtonTooltip text={tooltip} />}
		</div>
	);
};
